import { Component, OnInit } from '@angular/core';
import { ReportsService } from '../../services/reports.service';
import { UserDataService } from '../../services/user-data.service';
import { GeoService } from '../../services/geo.service';
import { formatDate } from '@angular/common';
import { HttpService } from 'src/app/services/http.service';
import {UntypedFormControl} from '@angular/forms';

import { firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'create-report-dialog',
  templateUrl: 'create-report.html',
})
export class createReportDialog implements OnInit{
  constructor(
    private reports: ReportsService,
    private user: UserDataService,
    private geo: GeoService,
    public translate: TranslateService,
    private http: HttpService,
  ) {}

  public rData = {
    'origem_deteccao':'',
    'causa_incendio':'',
    'comentarios':'',
    'geoloc_type_id': 2 // default = click no mapa
  }

  public lat:number;
  public lon:number;

  public datetime_deteccao;
  public date = new UntypedFormControl(new Date());

  public origens;
  public causas;
  public causasTranslated = {};
  public origensTranslated = {};


  async translateCausas() {
    for (let causa of this.causas) {
      const traducao = `FC.${causa.id_causa_incendio}`;
      this.causasTranslated[causa.id_causa_incendio] = await firstValueFrom(this.translate.get(traducao));
    }
  }

  async translateOrigem() {
    for (let origem of this.origens) {
      const traducao = `OD.${origem.id_origem_deteccao}`;
      this.origensTranslated[origem.id_origem_deteccao] = await firstValueFrom(this.translate.get(traducao));
    }
  }
  // TO DO - Refatorar forms com FormGroup, FormControl, sem NgModel etc.

  ngOnInit(): void {
    if(this.geo.clickedLatLng){
      let decimal_precision = 6
      this.lat = this.geo.clickedLatLng.lat.toFixed(decimal_precision)
      this.lon = this.geo.clickedLatLng.lng.toFixed(decimal_precision)
    }

    this.datetime_deteccao = formatDate(new Date(), 'yyyy-MM-ddTHH:mm', 'en-US')
    
    let report_params = this.user.getDadosPantera('report_params')
    this.origens = report_params.origens_deteccao
    this.causas = report_params.causas_incendio
    
    this.translateCausas();
    this.translateOrigem();
  }

  postReportData(){
    // TO DO fazer usando FormControl, sem getElementById
    const latField = document.getElementById('lat');
    const lonField = document.getElementById('lon');
    
    // se mexeu no forms, geoloc type = 4 (input lat lon)
    if (latField.classList.contains('ng-dirty') || lonField.classList.contains('ng-dirty')) {
      this.rData.geoloc_type_id = 4;
    }
    
    this.reports.createReport(this.lat, this.lon, this.datetime_deteccao, this.rData)
  }
  
}

