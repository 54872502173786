<main>
  <mat-tab-group id="group" (selectedTabChange)="amplitudeTabEvent($event['index'])">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">videocam</mat-icon>
        @if (user.isOperatorP2() || user.isSupportP2()) {
          {{ 'INTERFACE.CONTROLS' | translate }}
        }
        @else {
          {{ 'MAP.LIVE' | translate }}
        }
      </ng-template>
      <div class="tab-content-container">
        @if (user.isOperatorP2() || user.isSupportP2()) {
          <app-map-cameras-controls></app-map-cameras-controls>
        }
        @else {
          <app-map-camera></app-map-camera>
        }
      </div>
    </mat-tab>   

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">radar</mat-icon>
        Presets
      </ng-template>
      <div class="tab-content-container">
        <app-presets></app-presets>
      </div>
    </mat-tab>

    @if(user.isSupportP2()) {
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">settings</mat-icon>
        Calibração
      </ng-template>

      <div class="tab-content-container">
        <app-calibration-tab></app-calibration-tab>
      </div>
    </mat-tab>
    }

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">send_to_mobile</mat-icon>
        {{ 'MAP.ALERTS' | translate }}
      </ng-template>
      <div class="tab-content-container">
        <app-alert-groups></app-alert-groups>
      </div>
    </mat-tab>

  </mat-tab-group>
</main>