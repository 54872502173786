import { Component, Input, OnInit, signal  } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ClipboardService } from 'ngx-clipboard';
import { HttpService } from 'src/app/services/http.service';
import { UserDataService } from 'src/app/services/user-data.service';

const stackOrder = [
  'couchdb',
  'backend',
  'detection',
  'frontend',
  'model',
  'model2',
  'webrtc'
];

function sortByStackOrder(a: any, b: any): number {
  const indexA = stackOrder.indexOf(a.stack);
  const indexB = stackOrder.indexOf(b.stack);
  // If a stack is not in the custom order, it will be treated as last
  return (indexA === -1 ? stackOrder.length : indexA) -
         (indexB === -1 ? stackOrder.length : indexB);
}

@Component({
  selector: 'app-portainer-endpoint',
  templateUrl: './portainer-endpoint.component.html',
  styleUrls: ['./portainer-endpoint.component.scss']
})
export class PortainerEndpointComponent implements OnInit {

  constructor(
    private clipboardService: ClipboardService,
    private httpService: HttpService,
    private userData: UserDataService
){}

  @Input() public endpointData

  public stacksDataSource = new MatTableDataSource<any>();
  public stacksTableColums: string[] = [
    'stack',
    'version',
    'downloaded',
    'running',
    'deploy'
  ];

  public containers: any[]
  public containersTableColums: string[] = [
    "name",
    "state",
    "status",
    "image",
  ];

  public downloadedImages: string[];
  public deployParams;
  public lastUpdated;
  public endpointOnline = false; 
  public loading = true;
  public downloadedImagesLenght = signal<undefined | number>(0);
  public downloadedImagesSize = signal<undefined | number>(0);

  async ngOnInit(){
    console.log("endpointData:", this.endpointData)
    
    this.deployParams = await this.httpService.maestroGet(`get_deploy_params/${this.userData.getIdPlanta()}`);
    console.log('deployParams:', this.deployParams);
  
    this.stacksDataSource.data = this.endpointData.stacks?.map(stack =>{
      return {
        "stack": stack,
        "version": this.getStackVersion(stack),
        "downloaded": false,
        "running": false,
        "downloading": false,
        "deployRunning": false
      }
    })

    this.stacksDataSource.data.sort(sortByStackOrder);

    await this.fetchPortainerImagesContainers()

    console.log("stacksDataSource:",this.stacksDataSource.data)
  }

  getStackVersion(stack){
    const stackMapping = {
      'frontend': this.deployParams["frontend_version"],
      'backend': this.deployParams["wanted_pantera_version"],
      'couchdb': this.deployParams["wanted_pantera_version"],
      'detection': this.deployParams["wanted_pantera_version"],
      'model': this.deployParams["id_modelo"],
      'model2': this.deployParams["id_modelo"],
      'webrtc': 'HTTPP2'
    }
    return stackMapping[stack]
  }

  async fetchPortainerImagesContainers(){

    try{
            
      let response = await this.httpService.maestroGet(`get_portainer_images_containers/${this.endpointData.id_endpoint}`)
      console.log("get_portainer_images_containers:", response)

      if(response){

        this.setContainerTable(response.containers);
        this.setDownloadedImagesList(response.images);

        this.stacksDataSource.data.forEach(stackObject => this.checkContainerRunning(stackObject))
        this.stacksDataSource.data.forEach(stackObject => this.checkImageDownloaded(stackObject))
        
        this.endpointOnline = true;
      } else {
        this.endpointOnline = false;
      }

    } catch (error) {
      console.error("Erro ao carregar containers:", error);
      this.endpointOnline = false;

    } finally {
      this.loading = false;
      this.lastUpdated = new Date();
    }

  }
  
  setContainerTable(portainerContainersList){
    this.containers = portainerContainersList.map(container =>{

      const afterSlash = container.Image.split('/')[1];
      const [image, tag] = afterSlash.split(':');

      return {
        "name":container.Names[0],
        "state":container.State,
        "status":container.Status,
        "image":image,
        "tag":tag
      }
    })

    this.containers.sort((a, b) => a.name.localeCompare(b.name));
    
    console.log("initContainerTable", this.containers)
  }

  setDownloadedImagesList(portainerImagesList){
    this.downloadedImages = portainerImagesList.map(image =>{
      return image.RepoTags[0].split('/')[1]
    })

    this.downloadedImagesLenght.set(portainerImagesList.length)

    const imagesSize = portainerImagesList.reduce((acc, image) => acc + image.Size, 0);
    const imagesSizeInGb = imagesSize / 1e9;
    this.downloadedImagesSize.set((imagesSizeInGb)); 
  }


  checkContainerRunning(stackObject) {
    let container = this.containers.find(container => container.name.includes(stackObject.stack));

    if (container && container.tag === stackObject.version) {
        stackObject.running = true;
    } else {
        stackObject.running = false;
    }

  }

  checkImageDownloaded(stackObject){

    let stack = stackObject.stack.replace(/2/g, ""); // Remove  2 from model2
    let image = this.downloadedImages.find(image => image?.includes(`${stack}:${stackObject.version}`));
    if (image) {
      stackObject.downloaded = true;
    } else {
      stackObject.downloaded = false
    }
  }

  async pullDockerImage(element) {
    element.downloading = true;
    let stackVersion = this.getStackVersion(element.stack);
    const payload = {
      stack: element.stack,
      endpoint: this.endpointData.id_endpoint,
      versao: stackVersion,
    };

    try {
      let response = await this.httpService.maestroPost('pull_images_docker_portainer', payload);
      if (response === true) {
        this.fetchPortainerImagesContainers();
      }
    } catch (error) {
      console.error('Erro ao baixar imagem:', error);
      alert('Erro ao baixar a imagem.');
    } finally {
      element.downloading = false;
    }
  }

  async deployDockerStack(element) {
    element.deployRunning = true

    const payload = {
      idPlanta : this.userData.getIdPlanta(),
      stacks: [element.stack],
      user: this.userData.getUsername(),
      endpoint: this.endpointData.id_endpoint,
      deploy_params: this.deployParams
    };

    console.log('payload:', payload);

    try {
      let response = await this.httpService.maestroPost('deploy_portainer_p2', payload);
      console.log('deploy_portainer:', response);
      if (response[0] === 200) {
        await this.fetchPortainerImagesContainers();
      }
    } catch (error) {
      console.error('Erro ao dar Deploy:', error);
      alert('Erro ao dar Deploy.');
    }

    element.deployRunning = false

  }


  createDockerCmdPortainer() {
    const text = `docker rm -f portainer_edge_agent ;
        docker run -d \`
          -v /var/run/docker.sock:/var/run/docker.sock \`
          -v /var/lib/docker/volumes:/var/lib/docker/volumes \`
          -v /:/host \`
          -v portainer_agent_data:/data \`
          --restart always \`
          -e EDGE=1 \`
          -e EDGE_ID=${this.endpointData.id_edge} \`
          -e EDGE_KEY=${this.endpointData.key_edge} \`
          -e CAP_HOST_MANAGEMENT=1 \`
          -e EDGE_INSECURE_POLL=1 \`
          --name portainer_edge_agent \`
          portainer/agent:2.21.5`;

    this.clipboardService.copyFromContent(text);
    alert('Comando para deploy do portainer foi copiado');
  }

  goToPortainer(){
    window.open(`https://portainer.umgrauemeio.com/#!/${this.endpointData.id_endpoint}/docker/dashboard`)
  }

}

