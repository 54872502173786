import { Injectable } from '@angular/core';
import { GuidedTourService } from 'ngx-guided-tour';
import { AmplitudeService } from '../services/amplitude.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';

const layersOrient = [{
  orientationDirection: 'bottom',
  maximumSize: 600
}, 
{
  orientationDirection: 'right',
  maximumSize: 3000
}]

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  constructor(
    private guidedTourService: GuidedTourService,
    public translate: TranslateService,
    private amplitude: AmplitudeService
  ) { }

  private welcomeTour;
  private mapTour;
  public loadedSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private introVideoClickListenerAdded = false;

  public setLoaded(loaded: boolean): void {
    this.loadedSub.next(loaded);
  }

  startMapTour() {

    const version = environment.version;
    this.mapTour = {
      tourId: 'welcome-tour',
      useOrb: false,
      steps: [
        {
          title: this.translate.instant('TOUR.ONBOARDING.WELCOME_TITLE'),
          content: this.translate.instant('TOUR.ONBOARDING.WELCOME_CONTENT', { version }),
        },
        {
          title: this.translate.instant('TOUR.ONBOARDING.FEATURES_TITLE'),
          selector: '#features-nav',
          orientation: 'left',
          content: this.translate.instant('TOUR.ONBOARDING.FEATURES_CONTENT'),
        },
        {
          title: this.translate.instant('TOUR.ONBOARDING.MAP_LAYERS_TITLE'),
          selector: '#layers-btn',
          orientation: layersOrient,
          content: this.translate.instant('TOUR.ONBOARDING.MAP_LAYERS_CONTENT'),
        },
        {
          title: this.translate.instant('TOUR.ONBOARDING.FIRE_REPORTS_TITLE'),
          selector: '#ocorrencias-btn',
          orientation: 'left',
          content: this.translate.instant('TOUR.ONBOARDING.FIRE_REPORTS_CONTENT'),
        },
        {
          title: this.translate.instant('TOUR.ONBOARDING.CAMERAS_TITLE'),
          selector: '#cams-btn',
          orientation: 'left',
          content: this.translate.instant('TOUR.ONBOARDING.CAMERAS_CONTENT'),
        },
        {
          title: this.translate.instant('TOUR.ONBOARDING.SATELLITE_DETECTION_TITLE'),
          selector: '#satellites-btn',
          orientation:'left',
          content: this.translate.instant('TOUR.ONBOARDING.SATELLITE_DETECTION_CONTENT'),
        },
        {
          title: this.translate.instant('TOUR.ONBOARDING.RESOURCES_TITLE'),
          selector: '#brigades-btn',
          orientation:'left',
          content: this.translate.instant('TOUR.ONBOARDING.RESOURCES_CONTENT'),
        },
        {
          title: this.translate.instant('TOUR.ONBOARDING.WEATHER_TITLE'),
          selector: '#weather-btn',
          orientation:'left',
          content: this.translate.instant('TOUR.ONBOARDING.WEATHER_CONTENT'),
        },
        {
          title: this.translate.instant('TOUR.ONBOARDING.HISTORICAL_DATA_TITLE'),
          selector: '#historical-data-btn',
          orientation:'left',
          content: this.translate.instant('TOUR.ONBOARDING.HISTORICAL_DATA_CONTENT'),
        },
        {
          title: this.translate.instant('TOUR.ONBOARDING.BASE_LAYERS_TITLE'),
          selector: '#baseLayers-btn',
          orientation:layersOrient,
          content: this.translate.instant('TOUR.ONBOARDING.BASE_LAYERS_CONTENT'),
        },
        {
          title: this.translate.instant('TOUR.ONBOARDING.REPORT_LAYERS_TITLE'),
          selector: '#reportLayers-btn',
          orientation:layersOrient,
          content: this.translate.instant('TOUR.ONBOARDING.REPORT_LAYERS_CONTENT'),
        },
        {
          title: this.translate.instant('TOUR.ONBOARDING.CAMERAS_LAYERS_TITLE'),
          selector: '#visibilityLayers-btn',
          orientation:layersOrient,
          content: this.translate.instant('TOUR.ONBOARDING.CAMERAS_LAYERS_CONTENT'),
        },
        {
          title: this.translate.instant('TOUR.ONBOARDING.SATELLITE_LAYERS_TITLE'),
          selector: '#satLayers-btn',
          orientation:layersOrient,
          content: this.translate.instant('TOUR.ONBOARDING.SATELLITE_LAYERS_CONTENT'),
        },
        {
          title: this.translate.instant('TOUR.ONBOARDING.RESOURCE_LAYERS_TITLE'),
          selector: '#fmLayers-btn',
          orientation:layersOrient,
          content: this.translate.instant('TOUR.ONBOARDING.RESOURCE_LAYERS_CONTENT'),
        },
        {
          title: this.translate.instant('TOUR.ONBOARDING.RISK_LAYERS_TITLE'),
          selector: '#riskLayers-btn',
          orientation:layersOrient,
          content: this.translate.instant('TOUR.ONBOARDING.RISK_LAYERS_CONTENT'),
        },
        {
          title: this.translate.instant('TOUR.ONBOARDING.HISTORICAL_DATA_LAYERS_TITLE'),
          selector: '#historicalDataLayers-btn',
          orientation:layersOrient,
          content: this.translate.instant('TOUR.ONBOARDING.HISTORICAL_DATA_LAYERS_CONTENT'),
        }
      ],
      skipCallback: (stepSkippedOn: number) => this.skipOnboarding(stepSkippedOn),
      completeCallback: () => this.completeOnboarding()
    };
    this.guidedTourService.startTour(this.mapTour);
    this.observeIntroVideoClick();
  }

  observeIntroVideoClick(){
    const observer = new MutationObserver((mutations, obs) => {
      if (this.introVideoClickListenerAdded) {
        obs.disconnect();
        return;
      }
      const videoThumbnails = document.getElementsByClassName('pweb-video-thumbnail');
      if (videoThumbnails.length > 0) {
        const videoLink = videoThumbnails[0].parentElement;
        if (videoLink) {
          videoLink.addEventListener('click', this.trackIntroVideoClick.bind(this));
          this.introVideoClickListenerAdded = true;
          obs.disconnect()
        }
      }
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  }

  trackIntroVideoClick() {
    this.amplitude.sendEvent('Clicou Link', {'Destino': 'Loom', 'Assunto': 'Vídeo Introdução Pantera'})
  }

  skipOnboarding(stepSkippedOn:number) {
    this.introVideoClickListenerAdded = false
    for (let i=0; i < stepSkippedOn+1; i++){
      this.amplitude.sendEvent('Viu Etapa Onboarding', {'Etapa': i+1})
    }
    this.amplitude.sendEvent('Pulou Onboarding', {'Etapa': stepSkippedOn+1})
  }

  completeOnboarding(onboarding?:string){
    this.introVideoClickListenerAdded = false
    if (!onboarding){
      onboarding = 'Principal';
    }
    for (let i=0; i < this.mapTour['steps'].length; i++){
      this.amplitude.sendEvent('Viu Etapa Onboarding', {'Etapa': i+1})
    }
    this.amplitude.sendEvent('Completou Onboarding')
  }

}
