import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog  } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { InfoDialog } from "../info-dialog/info-dialog";
import { TranslateService } from '@ngx-translate/core';
import { AmplitudeService } from 'src/app/services/amplitude.service';

@Component({
  selector: 'app-nps-dialog',
  templateUrl: './nps-dialog.component.html',
  styleUrl: './nps-dialog.component.scss'
})
export class NpsDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpService,
    private dialogRef: MatDialogRef<NpsDialogComponent>,
    private dialog: MatDialog,
    private translate: TranslateService,
    private amplitude: AmplitudeService
  ){}

  public scores = Array.from({ length: 11 }, (_, i) => i);
  public selectedScore: number | null = null;
  public comment: string = '';

  async sendNps() {
    if (this.selectedScore === null) return;
    
    const payload = {
      username: this.data.username,
      score: this.selectedScore,
      comment: this.comment.trim() || null
    };

    let response = await this.http.maestroPost('insert_nps_data', payload)
    if(response.status){
        this.dialog.open(InfoDialog, {
          data: {text:`${this.translate.instant("NPS.THANK_YOU")}`}
        });
      this.dialogRef.close();
      this.amplitude.sendEvent('Pesquisa NPS', {"Ação": 'Respondeu NPS', "Nota": this.selectedScore});
    }else{
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translate.instant("MAP.ERROR")}`}
      });
    this.dialogRef.close();
    }

  }

  sendLater() {
    this.dialogRef.close();
    this.amplitude.sendEvent('Pesquisa NPS', {"Ação": 'Pulou NPS'});
  }
}
