import { Injectable, signal } from '@angular/core';
import { distance, point } from '@turf/turf';
import { PouchDBService } from './pouchdb.service';

const MANUAL_DETECTION_IMAGE_TYPES = ['5', '6']

const GEOLOC_TYPE_ID = {
    BY_2ND_CAMERA: 1,
    BY_2D_MAP: 2,
    BY_3D_MAP: 8,
}

@Injectable({
  providedIn: 'root'
})
export class DetectionsService {

    constructor(
        private pouchDBService: PouchDBService
    ) {}

    // --------------------------------- Service class attributes ---------------------------------
    public defaultImage = 'assets/logos/mock_logo_pantera.svg';
    public selectedDetectionImageURL = signal(this.defaultImage)
    public selectedDetection = signal(undefined)
    public selectedDetectionImages = signal([])
    public selectedDetectionCurrentImageIndex = signal(0);
    public selectedDetectionImageData = signal(undefined)
    public generatingReportStatusLoaderVisibility = signal(false)
    public detections = signal([])
    public isActivatedLocateFireByClickingOnMapState = signal<boolean>(false)
    public isActivatedLocateFireByClickingOn3DMapState = signal<boolean>(false)
    public fireLocationIsMarked = signal<boolean>(false)
    public lastLocationMarked = signal<any>(null)
    public lastUsedGeolocTypeId = signal<number | null>(null)
    public reclassTypes = undefined
    public reclassFlag = false;
    public idObject = 0;
    public geolocTypeId: number = undefined;
    public areGeolocButtonsEnabled = signal(false)
    public selectedReportToGroupDetectionOn = signal<any>(undefined)
    public isReclassDetMenuOpen = signal(false)
    public hasAlreadyPointedCameraOnce = signal(false)
    public reportsAndImagesList = signal<any[]>(undefined)

    // --------------------------------- State change and getters methods ---------------------------------
    setDetectionsList(list: any) {
        this.detections.set(list);
    }

    setSelectedDetection(detection: any, imgIndex: number = 0) {
        this.selectedDetectionCurrentImageIndex.set(imgIndex)
        this.selectedDetection.set(detection)
    }

    setSelectedDetectionCurrentImageIndex(value: number){
        this.selectedDetectionCurrentImageIndex.set(value)
    }

    setSelectedDetectionImages(images: any, imgIndex: number = 0) {
        const sortedImages = this.sortDetectionImages(images)
        this.selectedDetectionCurrentImageIndex.set(imgIndex);
        this.selectedDetectionImages.set(sortedImages); 
    }    

    clearSelectedDetection() {
        this.selectedDetection.set(null)
        this.disableGeolocButtons()
    }

    clearSelectedDetectionImages() {
        this.selectedDetectionImages.set([])
    }

    increaseSelectedDetectionImageCurrentIndex() {
        let numberOfImages = this.selectedDetectionImages().length
        if (this.selectedDetectionCurrentImageIndex() == (numberOfImages - 1)) {
            this.selectedDetectionCurrentImageIndex.set(0)
            return
        }
        this.selectedDetectionCurrentImageIndex.update(value => value + 1)
    }

    decreaseSelectedDetectionImageCurrentIndex() {
        let numberOfImages = this.selectedDetectionImages().length
        if (this.selectedDetectionCurrentImageIndex() == 0) {
            this.selectedDetectionCurrentImageIndex.set(numberOfImages - 1)
            return
        }
        this.selectedDetectionCurrentImageIndex.update(value => value - 1)
    }

    updateLastLocationMarked(location: any) {
        this.lastLocationMarked.set(location)
    }

    updateIsActivatedLocateFireByClickingOnMapState(value: boolean) {
        this.geolocTypeId = value ? GEOLOC_TYPE_ID.BY_2D_MAP : GEOLOC_TYPE_ID.BY_2ND_CAMERA;
        this.isActivatedLocateFireByClickingOnMapState.set(value)
    }

    updateIsActivatedLocateFireByClickingOn3DMapState(value: boolean) {
        this.geolocTypeId = value ? GEOLOC_TYPE_ID.BY_3D_MAP : GEOLOC_TYPE_ID.BY_2ND_CAMERA;
        this.isActivatedLocateFireByClickingOn3DMapState.set(value)
    }

    updateFireLocationIsMarked(value: boolean) {
        this.fireLocationIsMarked.set(value)
    }

    updateGeneratingReportStatusLoaderVisibilityValue(state: boolean) {
        this.generatingReportStatusLoaderVisibility.set(state)
    }

    updateLastUsedGeolocTypeId(geolocTypeId: number | null) {
        this.lastUsedGeolocTypeId.set(geolocTypeId)
    }
    
    enableGeolocButtons() {
        this.areGeolocButtonsEnabled.set(true)
    }

    disableGeolocButtons() {
        this.areGeolocButtonsEnabled.set(false)
    }

    updateSelectedReportToGroupDetectionOn(report: any) {
        this.selectedReportToGroupDetectionOn.set(report)
    }

    updateGeolocTypeId(value: number){
        this.geolocTypeId = value
    }

    setIsReclassDetMenuOpen(value: boolean){
        this.isReclassDetMenuOpen.set(value)
    }

    setReportsAndImagesList(value: any[]){
        this.reportsAndImagesList.set(value)
    }

    resetGeolocStates(){
        this.updateFireLocationIsMarked(false)
        this.updateIsActivatedLocateFireByClickingOnMapState(false)
        this.updateIsActivatedLocateFireByClickingOn3DMapState(false)
        this.updateGeneratingReportStatusLoaderVisibilityValue(false)
        this.updateLastLocationMarked(null)
    }

    // --------------------------------- Utilities methods ---------------------------------
    sortDetectionImages(images: any[]) {
        if (images.length > 1) {
            const sortedList = images.sort((a: any, b: any) => {
                return this.extractDatetimeFromDetectionImageFilename(b) - this.extractDatetimeFromDetectionImageFilename(a);
            });
            
            return sortedList
        }
        return images
    }
    
    extractDatetimeFromDetectionImageFilename(filename: string) {
        const datePiece = filename.split('_')[1]; 
        const timePiece = filename.split('_')[2];

        // replace - for :
        const fixedTimePiece = timePiece.replace(/-/g, ':');
        
        // format YYYY-MM-DD T HH:MM:SS
        return new Date(datePiece + "T" + fixedTimePiece).getTime();
    }

    getNumberOfRedetections(detectionObj) {
        return detectionObj.imgs.reduce((count: number, img: string) => {
            const imgType = img.split("_").slice(-2, -1)[0];
            return imgType === '1' ? count + 1 : count;
        }, 0);
    }

    isManualDetection(detectionObj){
        return detectionObj.imgs.some((img: string) => {
            const imgType = img.split("_").slice(-2, -1)[0];
            return MANUAL_DETECTION_IMAGE_TYPES.includes(imgType)
        })
    }

    getImgType(imgId: string): number | null {
        try {
            return parseInt(imgId.split("_").slice(-2, -1)[0])
        } catch {
            return null
        }
    }

    setHasAlreadyPointedCameraOnceValue(value: boolean) {
        this.hasAlreadyPointedCameraOnce.set(value);
    }

    getDistanceFromCameraToFire(cameraObj, fireLat: number, fireLon: number){
        try {
            const firePoint = point([fireLon, fireLat])
            const camPoint = point([cameraObj.lon, cameraObj.lat])
            const distanceFromCamToFire = distance(camPoint, firePoint, { units: "kilometers" })
            return distanceFromCamToFire != null ? parseFloat(distanceFromCamToFire.toFixed(2)) : null
        } catch(e) {
            console.warn(`Error getting distance from cam to fire: ${e}`)
            return null
        }
    }

    // ---------------- Suggest report to group detections ----------------------------

    async updateDetectionsSuggestedReportsToGroup() {
        const updateDetectionsPromises = this.detections().map(async (detection) => {
          await this.determineSuggestedIdReportToGroup(this.reportsAndImagesList(), detection);
        });
      
        await Promise.all(updateDetectionsPromises);
      }
    
      async determineSuggestedIdReportToGroup(reportsList: any[], detection: any) {
        if (!detection || !reportsList) return;
      
        const bestReportCandidate = this.getBestReportCandidateToGroupDetection(reportsList, detection);
        detection['suggested_id_report_to_group'] = bestReportCandidate?.id_report ?? null;
      
        try {
          await this.updateDetectionDocSuggestedIdReportToGroup(detection);
        } catch (err: any) {
          console.warn(`Failed to update detection: ${err}`);
        }
      }
    
      getBestReportCandidateToGroupDetection(reportsList: any[], detection: any) {
        try {
            const selectedDetectionCameraId = detection?.id_cam
            const hasImageAndSameCameraReportsList = this.extractHasImageAndSameCameraReports(selectedDetectionCameraId, reportsList)
            const { pan: detectionPanGroupingRef, zoom: detectionZoomGroupingRef } = detection?.ptz_grouping_ref
            const winner = this.extractClosestReport(detectionPanGroupingRef, detectionZoomGroupingRef, hasImageAndSameCameraReportsList)
            return winner.report
        } catch (e) {
            console.warn(`Error getting best report candidate to group detection: ${e}`)
            return null
        }
      }
    
      extractHasImageAndSameCameraReports(cameraId: number, reportsList: any[]) {
        return reportsList.filter((report: any) => {
          return report.images.length > 0 && report.images[0]?.img_details.id_cam == cameraId
        })
      }
    
      extractClosestReport(detectionPan: number, detectionZoom: number, reportsList: any[]) {
        const effectivePanDiffTsh = Math.min(5, 30/detectionZoom) // pan diff de 1º a 5º dependendo do zoom

        let winner = { report: undefined, diff: undefined };
    
        for (let rep of reportsList) {
          if (rep.images.length > 0) {
            for (let img of rep.images) {
              const diff = this.subtractPans(detectionPan, img.img_details.pan)
              if ((diff < effectivePanDiffTsh) && (winner.diff == undefined || diff < winner.diff)) {
                winner.report = rep
                winner.diff = parseFloat(diff.toFixed(2))
              }
            }
          }
        }
        return winner
      }
    
      subtractPans(pan1: number, pan2: number) {
        let diff = Math.abs(pan1 - pan2);
        if (diff >= 180) {
          diff = 360 - diff;
        }
        return diff;
      }
    
      async updateDetectionDocSuggestedIdReportToGroup(detection: any){ 
        try {
            const document = await this.pouchDBService.fetchDocumentOnDBById(detection._id)
            if (document) {
                document.suggested_id_report_to_group = detection.suggested_id_report_to_group;
                await this.pouchDBService.updateDocument(document);
            }
        }
        catch (err: any) {
            console.warn(`An error occurred while trying to set detection's suggested_id_report_to_group: ${err}`)
        }
      }

    // ---------------------------------------------------------------------------------
}