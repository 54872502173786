<main>

  <mat-toolbar class="toolbar">
    {{ 'MAP.WELCOME' | translate }} {{user.nome}}
    <div style="margin-left: auto;">

      @if (user.isAdmin()) {
        <button mat-icon-button
          matTooltip="Admin"
          [routerLink]="['/admin']">
          <mat-icon>admin_panel_settings</mat-icon>
        </button>
      }

      <button mat-icon-button
        matTooltip="{{'MAP.LOGOUT' | translate}}"
        (click)="logout()">
        <mat-icon>logout</mat-icon>
      </button>
    </div>
  </mat-toolbar>

  <mat-accordion>
    <mat-expansion-panel class="config-panel">

      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>account_circle</mat-icon>
          <span class='panel-title'>
            {{ 'MAP.ACCESS_DATA' | translate }}
          </span>
        </mat-panel-title>

        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-card appearance="outlined" class="settings-card">
        <mat-card-header>
          <mat-card-title>{{user.nome}} {{user.sobrenome}}</mat-card-title>
          <mat-card-subtitle>{{ 'MAP.USERNAME' | translate }} : {{user.username()}}</mat-card-subtitle>
        </mat-card-header>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'MAP.CURRENT_PASSWORD' | translate }}</mat-label>
          <span matPrefix>
            <mat-icon>vpn_key</mat-icon>
          </span>
          <input
            matInput
            [type]="showPass1 ? 'text' : 'password'"
            [(ngModel)]="pass1"
            />
          <button mat-icon-button matSuffix (click)="showPass1 = !showPass1">
            <mat-icon>{{
              showPass1 ? "visibility" : "visibility_off"
            }}</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{ 'MAP.NEW_PASSWORD' | translate }}</mat-label>
          <span matPrefix>
            <mat-icon>vpn_key</mat-icon>
          </span>
          <input matInput [type]="showPass2 ? 'text' : 'password'" [(ngModel)]="pass2"
            name="password"  #password="ngModel" 
            (keydown.space)="$event.preventDefault()"
            matTooltip="{{ 'MAP.PASSWORD_REQUIREMENT' | translate }}"
            [matTooltipDisabled]="!passwordHints && !pass2" matTooltipPosition="above" 
            matTooltipClass="password-tooltip" (focus)="passwordTooltips(true)"  (blur)="passwordTooltips(false)"/>
          <button mat-icon-button matSuffix (click)="showPass2 = !showPass2">
            <mat-icon>{{
              showPass2 ? "visibility" : "visibility_off"
            }}</mat-icon>
          </button>
        </mat-form-field>
          
        <mat-form-field appearance="outline">
          <mat-label>{{ 'MAP.CONFIRM_NEW_PASSWORD' | translate }}</mat-label>
          <span matPrefix>
            <mat-icon>vpn_key</mat-icon>
          </span>
          <input matInput [type]="showConfirmPass2 ? 'text' : 'password'"  [(ngModel)]="confirmPass2"
            name="confirmPassword"  #confirmPassword="ngModel" 
            (keydown.space)="$event.preventDefault()"
            matTooltip="{{ 'MAP.PASSWORDS_MUST_MATCH' | translate }}"
            [matTooltipDisabled]="!passwordHints && !pass2" matTooltipPosition="above"
            matTooltipClass="password-tooltip" (focus)="passwordTooltips(true)" (blur)="passwordTooltips(false)"/>
            <button mat-icon-button matSuffix (click)="showConfirmPass2 = !showConfirmPass2">
              <mat-icon>{{
                showConfirmPass2  ? "visibility" : "visibility_off"
              }}</mat-icon>
            </button>
        </mat-form-field>
        <div align="center">
          <button mat-raised-button (click)="changePassword()" color="primary" [disabled]="!validPassword()">
            {{ 'MAP.UPDATE' | translate }}
          </button>
        </div>
      </mat-card>
    </mat-expansion-panel>

    <mat-expansion-panel class="config-panel">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>settings</mat-icon>
          <span class='panel-title'>
            {{ 'SETTINGS.SETTINGS' | translate }}
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-card>
        <div class="settings-options">
          <div>
            <div>
              {{ 'SETTINGS.THEME' | translate }}
            </div>
            <mat-button-toggle-group (change)="colorSchemeChange($event.value)">
              <mat-button-toggle [checked]="!user.darkMode()" [value]="false">
                <div class="icon-text-container">
                  <mat-icon [ngClass]="'icons-light'">light_mode</mat-icon>
                  <span>{{ 'SETTINGS.LIGHT_THEME' | translate }}</span>
                </div>
              </mat-button-toggle>
              <mat-button-toggle [checked]="user.darkMode()" [value]="true">
                <div class="icon-text-container">
                  <mat-icon [ngClass]="'icons-dark'">dark_mode</mat-icon>
                  <span>{{ 'SETTINGS.DARK_THEME' | translate }}</span>
                </div>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </mat-card>

    <!-- gambi provisoria -->
    @if (!isIndia){
      <mat-card>
        <div class="language-picker">
          <div>
            {{ 'SETTINGS.LANGUAGE' | translate }}
          </div>
          <mat-button-toggle-group (change)="onToggleChange($event.value)">
            <mat-button-toggle [checked]="translate.currentLang=='pt-br'" [value]="'pt-br'">
              <span>Português</span>
            </mat-button-toggle>
            <mat-button-toggle [checked]="translate.currentLang=='en'" [value]="'en'">
              <span>English</span>
            </mat-button-toggle>
            <mat-button-toggle [checked]="translate.currentLang=='es'" [value]="'es'">
              <span>Español</span>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </mat-card>
      }
    
      <mat-card class="settings-options">
        <div class="toggle-container">
          <mat-slide-toggle [checked]="user.audioState()" (change)="toggleAudio()" color="warn">
            <div class="icon-text-container">
            @if(!user.audioState()){
              <mat-icon [ngClass]="user.darkMode() ? 'icons-dark' : 'icons-light'">notifications_off</mat-icon>
            }
            @if(user.audioState()){
              <mat-icon [ngClass]="user.darkMode() ? 'icons-dark' : 'icons-light'">notifications_active</mat-icon>
            }
            <span>{{ 'MAP.SOUND' | translate }}</span>
          </div>
          </mat-slide-toggle>
        </div>
      </mat-card>

      @if(user.isOperatorP2() || user.isSupportP2()){
        <mat-card class="settings-options">
          <div class="toggle-container">
            <mat-slide-toggle [checked]="user.drawAutoGeolocEllipse()" (change)="toggleDrawAutoGeolocEllipse($event.checked)" color="warn">
              <div class="icon-text-container">
              @if (!user.drawAutoGeolocEllipse()){
                <mat-icon [ngClass]="user.darkMode() ? 'icons-dark' : 'icons-light'">location_off</mat-icon>
              } @else {
                <mat-icon [ngClass]="user.darkMode() ? 'icons-dark' : 'icons-light'">location_on</mat-icon>
              }
              <span>{{ 'SETTINGS.AUTOMATIC_APPROXIMATE_GEOLOCATION' | translate }}</span>
            </div>
            </mat-slide-toggle>
          </div>
        </mat-card>

        <mat-card class="settings-options">
          <div class="toggle-container">
            <mat-slide-toggle [checked]="user.userPrefsStatus().data.telegramStatus" (change)="toggleTelegram($event.checked)" color="warn">
              <div class="icon-text-container">
              @if(!user.userPrefsStatus().data.telegramStatus){
                <mat-icon [ngClass]="user.darkMode() ? 'icons-dark' : 'icons-light'">speaker_notes_off</mat-icon>
              } @else {
                <mat-icon [ngClass]="user.darkMode() ? 'icons-dark' : 'icons-light'">speaker_notes_on</mat-icon>
              }
              <span>Telegram</span>
            </div>
            </mat-slide-toggle>
          </div>
        </mat-card>
      }
      @if (user.isSupportP2()){
        <mat-card class="settings-options">
          <div class="toggle-container">
            <mat-slide-toggle [checked]="user.userPrefsStatus().data.idleTurnAutoModeStatus" (change)="toggleIdleTurnAutoModeStatus($event.checked)" color="warn">
              <div class="icon-text-container">
              @if(!user.userPrefsStatus().data.idleTurnAutoModeStatus){
                <mat-icon [ngClass]="user.darkMode() ? 'icons-dark' : 'icons-light'">radar</mat-icon>
              } @else {
                <mat-icon [ngClass]="user.darkMode() ? 'icons-dark' : 'icons-light'">radar</mat-icon>
              }
              <span>Ativação inteligente da detecção automática</span>
            </div>
            </mat-slide-toggle>
          </div>
        </mat-card>

        <mat-card class="settings-options">
          <div class="number-form fx-row-space-between-center">
            <mat-form-field appearance="fill" matTooltip="{{ 'SETTINGS.NUMBER_OF_DETECTIONS_TO_ALERT_TOOLTIP' | translate }}">
              <mat-label>{{ 'SETTINGS.NUMBER_OF_DETECTIONS_TO_ALERT' | translate }}</mat-label>
              <input matInput type="number" min="0" [(ngModel)]="numberOfRedetectionsFormValue" (ngModelChange)="onNumberOfRedetectionsChange($event)">
            </mat-form-field>
            <button mat-icon-button (click)="syncNumberOfRedetectionsChange()">
              <mat-icon>save</mat-icon>
            </button>
          </div>
        </mat-card>
      }
        
    </mat-expansion-panel>

    @if(user.isOperatorP2() || user.isSupportP2()){
    <app-loading class="loader" *ngIf="this.updating" [message]="updateMessage"></app-loading>
    <mat-expansion-panel class="config-panel">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>cloud_sync</mat-icon>
          <span class='panel-title'>
            {{ 'MAP.DATA_SYNC' | translate }}
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-card class="settings-card data-sync-btns">
        <div>
          <button class="common-btn" mat-raised-button color="primary" (click)="reloadInterface()">
            <div class="icon-text-container">
                <mat-icon [ngClass]="[user.darkMode() ? 'icons-light' : 'icons-dark', 'icon-space']">sync</mat-icon>
              <span>{{ 'MAP.RELOAD_INTERFACE' | translate }}</span>
            </div>
          </button>
        </div>
        <div>
          <button  class="common-btn"  mat-raised-button color="primary"  (click)="atualizarDados()">
            <div class="icon-text-container">
              <mat-icon [ngClass]="[user.darkMode() ? 'icons-light' : 'icons-dark', 'icon-space']">cloud_download</mat-icon>
            <span>{{ 'MAP.UPDATE_DATA' | translate }}</span>
          </div>
        </button>
        </div>
        @if (user.isSupportP2()){
          <div>
            <button  class="common-btn"  mat-raised-button color="primary"  (click)="deleteAllDetections()">
              <div class="icon-text-container">
                <mat-icon [ngClass]="[user.darkMode() ? 'icons-light' : 'icons-dark', 'icon-space']">delete</mat-icon>
              <span>{{ 'MAP.DELETE_ALL_DETECTIONS' | translate }}</span>
            </div>
          </button>
          </div>
        }
      </mat-card>
    </mat-expansion-panel>

  }

    @if(user.isDev() || user.isManager()){
    <mat-expansion-panel class="config-panel">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>group</mat-icon>
          <span class='panel-title'>
            {{ 'MAP.USERS' | translate }}
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
  
      <div class="table-container">
        <table mat-table [dataSource]="usersPlanta" matSort  matSortActive="username" matSortDirection="asc" matSortDisableClear = "true" class="mat-elevation-z8 full-width-table">
          <ng-container matColumnDef="id_role">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo usuário </th>
            <td mat-cell *matCellDef="let element" style="font-size: 12px;"> {{element.id_role}}</td>
          </ng-container>
          <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'MAP.USERNAME' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{element.username}} </td>
          </ng-container>
          <ng-container matColumnDef="nome">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'MAP.FIRST_NAME' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{element.nome}} </td>
          </ng-container>
          <ng-container matColumnDef="sobrenome">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'MAP.LAST_NAME' | translate }}  </th>
            <td mat-cell *matCellDef="let element"> {{element.sobrenome}} </td>
          </ng-container>
          <ng-container matColumnDef="last_access">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Último acesso P.Web </th>
            <td mat-cell *matCellDef="let element"> {{element.last_access | date : "dd/MM/YY HH:mm:ss"}} </td>
          </ng-container>
          @if(user.isDev()){
          <tr mat-header-row *matHeaderRowDef="usersTableColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: usersTableColumns;"></tr>
          }@else{
            <tr mat-header-row *matHeaderRowDef="usersTableColumnsManager; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: usersTableColumnsManager;"></tr>
          }
        </table>
      </div>
      
    </mat-expansion-panel>
  
    }

  </mat-accordion>
</main>
