import { Injectable, signal } from '@angular/core';
import * as L from 'leaflet';

export const iconDirectory = './assets/icons/'
export const tipoLocal2Icon = {
1: 'cco.png',
2: 'icon-tower-green.png',
3: 'icon-tower-grey.png',
// 4: 'icon-tower-grey.png',
5: 'red-pin.png',
6: 'icon-tower-blue.png'
}

const MONITORING_TOWER_ID_TIPO_LOCAL = 2
const SELECTED_TOWER_ICON = 'icon-tower-orange.png'
export const DEFAULT_ICON_SIZE = [32, 32]

@Injectable({
  providedIn: 'root'
})
export class GeoMarkersService {
  public allMarkers = signal<L.Marker[]>([]);

  getMarkerByCameraId(cameraId: number) {
    return this.allMarkers().find(marker => marker.feature?.properties?.id_camera === cameraId);
  }

  addMarkerToList(marker: L.Marker) {
    const markers = this.allMarkers()
    this.allMarkers.set([...markers, marker])
  }

  highlightTowerMarker(marker: L.Marker) {
    const selectedTowerIcon = L.icon({
      iconSize: DEFAULT_ICON_SIZE,
      iconUrl: iconDirectory + SELECTED_TOWER_ICON
    });
    marker.setIcon(selectedTowerIcon);
  }

  resetTowerMarkerToDefault(marker: L.marker) {
    const defaultIcon = L.icon({
      iconSize: DEFAULT_ICON_SIZE,
      iconUrl: iconDirectory + tipoLocal2Icon[MONITORING_TOWER_ID_TIPO_LOCAL]
    });
    marker.setIcon(defaultIcon);
  }
}
