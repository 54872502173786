import { Injectable, computed, effect, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import * as L from 'leaflet';
import * as gf from "./geo.format";
import { GeoMarkersService, iconDirectory, tipoLocal2Icon, DEFAULT_ICON_SIZE } from './marker.service';

const MONITORING_TOWER_ID_TIPO_LOCAL = 2
const REPEATER_TOWER_ID_TIPO_LOCAL = 3

type GeoJSONOptions = {
  pointToLayer: (feature: any, latlng: any) => any;
  onEachFeature?: (feature: any, layer: any) => void;
};
export interface CamCoordsbj {
    camId: number,
    lat: number,
    lng: number
}

let toolTipOpts = {
  direction: 'bottom',
  offset: [0, 15],
  opacity: 0.9
}

@Injectable({
  providedIn: 'root'
})
export class GeoFactoryService {
  
  public selectedCamerasObjectsToControlFromMap = signal<CamCoordsbj[]>([])
  public selectedCamerasIdsToControlFromMap = computed(() => {
    let updatedArray = []
    this.selectedCamerasObjectsToControlFromMap().forEach((cameraObj: CamCoordsbj) => {
      updatedArray.push(cameraObj.camId)
    })
    return updatedArray
  })

  public rulerEnabled = signal<boolean>(false);

  constructor(
    public geoMarkersService: GeoMarkersService
  ) {
    effect(() => {
      this.handleTowerInteractionsOnRulerEnabled()
  });
  
  }

  createPlantaLocaisGeoJSON(locais_planta, all_instalacoes, filter_id_tipo_local=[2], isUgm=false, isUserP2=false, translate: TranslateService, fullInfoLayer=true) {
    let options: GeoJSONOptions = {
      pointToLayer: (feature, latlng) => {
        if (!filter_id_tipo_local.includes(feature.properties.id_tipo_local)) return;
  
        let marker = L.marker(latlng, { icon: this.getLocalIcon(feature.properties.id_tipo_local) });
  
        if ([MONITORING_TOWER_ID_TIPO_LOCAL, REPEATER_TOWER_ID_TIPO_LOCAL].includes(feature.properties.id_tipo_local)) {
          var displayName;
          var tooltipClass;
          if (isUgm) {
            let solar = feature.properties.energia_concessionaria ? "" : " ☀️";
            displayName = feature.properties['nome_ugm'] + solar;
            tooltipClass = feature.properties.internet ? "marker-label internet" : "marker-label";
          } else {
            displayName = feature.properties['nome_local'];
            tooltipClass = "marker-label";
          }
          const tooltipPermanent = feature.properties?.id_camera != null  || isUgm
          marker.bindTooltip(displayName, {
            permanent: tooltipPermanent,
            className: tooltipClass,
            ...toolTipOpts
          });
        }
  
        if (fullInfoLayer && isUgm){
          let instalacoes = all_instalacoes.filter(i => i.id_local == feature.properties.id_local)[0] || {};
          marker.popupContent = gf.formatUgmTowerInfo(feature, instalacoes, translate)
          marker.bindPopup(marker.popupContent);
          gf.addActivatedPopup({
              tipo: 'Tower',
              marker: marker,
              feature: feature,
              instalacoes_object: instalacoes
          });
          } else if (!fullInfoLayer || !isUserP2) {
            marker.popupContent = gf.formatClientTowerInfo(feature, translate) 
            marker.bindPopup(marker.popupContent);
          }

          marker.fullInfoLayer = fullInfoLayer;
          marker.isUserP2 = isUserP2;
  
        this.geoMarkersService.addMarkerToList(marker)
        return marker;
      }
    };
    
    let geoJsonLayer = L.geoJSON(locais_planta, options);
    
    return geoJsonLayer;
  }
  
  handleTowerSelectionToControlFromMap(towerMarker){
    if (!towerMarker?.feature?.properties?.id_camera) return

    if (this.selectedCamerasIdsToControlFromMap().includes(towerMarker.feature.properties.id_camera)) {
      this.unselectTowerToControlFromMapRoutine(towerMarker)
      return
    }
    this.selectTowerToControlFromMapRoutine(towerMarker)
  }
  
  
  createAllTowersLayer(allLocais){
    return L.geoJSON(allLocais, {
      pointToLayer: (feature, latlng) => {
        if (feature.properties.id_tipo_local != 2) return;

        let marker = L.marker(latlng, {icon: this.getLocalIcon(feature.properties.id_tipo_local)})
        marker.bindTooltip(feature.properties.nome_ugm, toolTipOpts);
        return marker;
      }
    });
  }

  getLocalIcon(id_tipo_local: any, iconSize:number[] = DEFAULT_ICON_SIZE){
    let icon_type = tipoLocal2Icon[id_tipo_local]
  
    return L.icon({
      iconSize: iconSize,
      iconUrl: iconDirectory + icon_type
    });
  }

  handleTowerInteractionsOnRulerEnabled(){
    const rulerEnabled = this.rulerEnabled();
  
    this.geoMarkersService.allMarkers().forEach(marker => {
        if (rulerEnabled) {
            marker.unbindPopup();
            marker.off('click');
        } else {
          if (marker?.popupContent){
            marker.bindPopup(marker.popupContent);
          }
          marker.on('click', e => {
            if (marker?.fullInfoLayer && marker?.isUserP2) {
              this.handleTowerSelectionToControlFromMap(marker)
            }
          })
        }
    });
  }

  addSelectedCamerasObjectsToControlFromMap(cameraObj: CamCoordsbj) {
    this.selectedCamerasObjectsToControlFromMap.update(value => [...value, cameraObj])
  }

  removeSelectedCameraObjectToControlFromMap(cameraObj: CamCoordsbj) {
    const filteredArray = this.selectedCamerasObjectsToControlFromMap().filter((camera) => {
      return camera.camId != cameraObj.camId
    })
    this.selectedCamerasObjectsToControlFromMap.set(filteredArray)
  }
  
  selectTowerToControlFromMapRoutine(layer: L.Marker) {
    this.addSelectedCamerasObjectsToControlFromMap({
      camId: layer.feature.properties.id_camera,
      lat: layer.feature.properties.lat,
      lng: layer.feature.properties.lon
    })
    this.geoMarkersService.highlightTowerMarker(layer);
  }

  unselectTowerToControlFromMapRoutine(layer: L.Marker) {
    const cameraId = layer.feature.properties.id_camera
    const cameraLat = layer.feature.properties.lat
    const cameraLng = layer.feature.properties.lng
    
    this.removeSelectedCameraObjectToControlFromMap({
      camId: cameraId,
      lat: cameraLat,
      lng: cameraLng
    })
    this.geoMarkersService.resetTowerMarkerToDefault(layer);
  }
}