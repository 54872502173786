import { Component, Input, OnInit, signal } from '@angular/core';
import { UserDataService } from 'src/app/services/user-data.service';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialog } from 'src/app/shared/dialogs/info-dialog/info-dialog';
import { GeoService } from 'src/app/services/geo.service';
import { IUpdateOffsetValueDTO } from 'src/app/shared/interfaces/IUpdateOffsetValueDTO';
import { Httpv2Service } from 'src/app/services/httpv2.service';

@Component({
  selector: 'app-calibration-tab',
  templateUrl: './calibration-tab.component.html',
  styleUrls: ['./calibration-tab.component.scss']
})
export class CalibrationTabComponent implements OnInit {

  public camerasList: any[];
  public selectedCamera: any;
  public selectedCameraOffset: any;
  public isSavingOffsetValueChange = signal<boolean>(false);

  constructor(
    public user: UserDataService,
    private dialog: MatDialog,
    private geo: GeoService,
    private httpv2: Httpv2Service
  ) { }

  ngOnInit() {
    this.getCams();
    if (this.camerasList.length > 0) {
      this.setSelectedCamera(this.camerasList[0]);
      this.setSelectedCameraOffset(this.selectedCamera.offset_pan);
    }
  }

  getCams(){
    const camerasData = this.user.getDadosPantera('cameras');
    camerasData.sort((c1, c2) => c1.id - c2.id);
    this.camerasList = camerasData;
  }

  async updateOffsetValue(inputOffsetValue: number){
    if (inputOffsetValue == this.selectedCamera.offset_pan) {
      return this.openInfoDialog("Digite um valor diferente para atualizar o offset desta câmera")
    }
    this.setIsSavingOffsetValueChangeState(true);
    const offset = parseFloat(JSON.stringify(inputOffsetValue));

    if (!this.offsetInputValidator(offset)) {
      this.setIsSavingOffsetValueChangeState(false);
      return
    }

    let updateOffsetValueData: IUpdateOffsetValueDTO = {
      'id': this.selectedCamera.id,
      'offset_pan': parseFloat(JSON.stringify(offset))
    };

    try {
      const backendResponse = await this.requestUpdateCamOffsetValue(updateOffsetValueData);
      if (backendResponse.status) {
        this.httpv2.getFromBackendP2Promise('/maestro/atualizar_dados_cliente')
        .then(() => {
          this.user.updatePanteraData()
          .then(() => {
            this.setIsSavingOffsetValueChangeState(false);
            this.openInfoDialog("Sucesso ao atualizar valor do offset")
          })
        })
      }
    }
    catch (err) {
      this.setIsSavingOffsetValueChangeState(false)
      console.log("Erro ao atualizar valor do offset", err)
      this.openInfoDialog("Erro ao atualizar o offset")
    }
  }
  
  offsetInputValidator(value: number) {
    let isValid = true;
    if (value < 0) {
      isValid = false;
      this.openInfoDialog("Digite apenas valores positivos")
    }
    else if (value >= 360) {
      isValid = false;
      this.openInfoDialog("Digite apenas valores entre 0 e 359,99")
    }
    if (Number.isNaN(value)) {
      isValid = false;
      this.openInfoDialog("Não deixe o campo em branco")
    }
    return isValid;
  }

  async requestUpdateCamOffsetValue(data: IUpdateOffsetValueDTO): Promise<any> {
    return await this.httpv2.postToBackendP2Promise('/maestro/update_cam_offset', data)
  }

  changeCam(){
    this.getCams();
    this.setSelectedCameraOffset(this.selectedCamera.offset_pan);
    this.geo.centerOnObject({ lat: this.selectedCamera.lat, lng: this.selectedCamera.lon })
  }

  setIsSavingOffsetValueChangeState(value: boolean) {
    this.isSavingOffsetValueChange.set(value)
  }

  openInfoDialog(content: string) {
    return this.dialog.open(InfoDialog, {
        data: { text: content }
    })
  }

  setSelectedCamera(camera: any) {
    this.selectedCamera = camera;
  }

  setSelectedCameraOffset(value: number) {
    this.selectedCameraOffset = value;
  }
}