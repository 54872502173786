<main>
  <mat-dialog-content>
      <div class="dialog-container">
          <h3>{{ 'NPS.HELLO' | translate }}, {{data.name}}. {{ 'NPS.PLEASE_RATE' | translate }}</h3>
          <div class="radio-container">
            <mat-radio-group [(ngModel)]="selectedScore" class="radio-group" color="warn">
              @for(score of scores; track score){
                <div class="radio-item">
                  <mat-radio-button [value]="score"></mat-radio-button>
                  <span class="radio-label">{{score}}</span>
                </div>
            }
            </mat-radio-group>
          </div>
          
        <div class="comment-container">  
          <mat-form-field appearance="outline" class="comment-field">
            <mat-label>{{ 'NPS.COMMENTS' | translate }}</mat-label>
            <textarea matInput [(ngModel)]="comment"></textarea>
          </mat-form-field>
        </div>
      </div>
  </mat-dialog-content>
  
  <mat-dialog-actions align="center" class="dialog-actions">
    <span class="send-later" (click)="sendLater()">{{ 'NPS.SUBMIT_LATER' | translate }}</span>
    <button mat-raised-button [disabled]="selectedScore === null" color="primary" (click)="sendNps()">
      {{ 'NPS.SUBMIT' | translate }}
    </button>
  </mat-dialog-actions>
</main>
  
