import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ColorSchemeService } from './services/color-scheme.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { SwUpdate } from '@angular/service-worker';

const updateAvailable = 'UPDATE_AVAILABLE';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'pantera-web';
  public showHeader: boolean = true;

  constructor(
    public translate: TranslateService,
    private colorScheme: ColorSchemeService,
    public router: Router,
    private swUpdate: SwUpdate,
    ) {
    if (localStorage.getItem(updateAvailable) === 'true'){
      console.warn('Reloading to new version...');
      localStorage.removeItem(updateAvailable);
      window.location.reload();
    };
  }

  ngOnInit() {
    this.translate.addLangs(['pt-br', 'en', 'es']);
    this.translate.setDefaultLang('pt-br');
    this.translate.use('pt-br');
    this.colorScheme.load();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const path = event.url.split('?')[0];
      const pathAfterRedirect = event.urlAfterRedirects.split('?')[0];
      const routesWithoutHeader = ['/login', '/login-sso', '/interface', '/project', '/propagation', '/labelling', '/home', '/mosaic']; 
      // evitar bug no caso /routeWithoutHeader/map
      if (!routesWithoutHeader.includes(path) || pathAfterRedirect !== '/map'){
        // fazer assim e nao o contrario (routesWithHeader) para funcionar para rotas invalidas redirecionadas
        this.showHeader = !routesWithoutHeader.includes(path);
      }
    });
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.pipe(
        take(1)
      ).subscribe(event => {
        switch (event.type) {
          case 'VERSION_READY':
            console.warn('⚠️ A new version is available');
            localStorage.setItem(updateAvailable, 'true');
            break;
          case 'NO_NEW_VERSION_DETECTED':
            console.log('✅ App is at latest version');
            localStorage.removeItem(updateAvailable);
            break;
          default:
            console.log('Latest version check failed');
            localStorage.setItem(updateAvailable, 'true');
        }
      });
    }
  }

}
