import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class BroadcastService {

    constructor() {
        this.broadcastChannel = new BroadcastChannel('interface-map-communication');
    }

    // --------------------------------- Service class attributes ---------------------------------
    public broadcastChannel: BroadcastChannel


    // --------------------------------- API calls methods ---------------------------------
    publishMessage(message: any) {
        this.broadcastChannel.postMessage(message)
    }

    resetGeolocStates(clearGeolocEllipse: boolean = true) {
        this.publishMessage({ event: "ClearFireLocationMarker" })
        this.publishMessage({ event: "SearchOnMap", value: false })
        this.publishMessage({ event: "RestoreCursor"})
        if (clearGeolocEllipse){
            this.publishMessage({ event: "ClearGeolocEllipse"})
        }
    }
}