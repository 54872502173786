import { Component, OnInit, OnDestroy, effect, Injector } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

import { ReportsService } from '../../services/reports.service';
import { UserDataService } from '../../services/user-data.service';
import { HttpService } from '../../services/http.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { PropagationService } from '../../services/propagation.service';
import { BrigadesService } from 'src/app/services/brigades.service';

import { addTextDialog, SelectPerdasDialog, LatLonDialog } from 'src/app/reports-components/reports-dialog/reports-dialog';
import { SelectBrigadeDialog } from 'src/app/reports-components/reports-dialog/select-brigade-dialog';
import { DownloadReportDialog } from 'src/app/reports-components/download-report/download-report-dialog';
import { InfoDialog } from "../../shared/dialogs/info-dialog/info-dialog";

import { AddImagesDialog } from '../../shared/dialogs/add-img-dialog/add-img-dialog'
import { ConfirmationDialog } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GeoService } from 'src/app/services/geo.service';
import { formatDate } from '@angular/common';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { TranslateService } from '@ngx-translate/core';
import { LoggingService } from '../../services/logging.service';
import { firstValueFrom } from 'rxjs';
import { SocketService } from '../../interface/services/socket.service';
import { CamerasService } from 'src/app/interface/services/cameras.service';
import { FilterService } from 'src/app/services/filter.service';
import { BroadcastService } from 'src/app/interface/services/broadcast.service';
import { GeoLayersService } from 'src/app/services/geo.layers';

const MS_TO_KMH = 3.6;

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {
  constructor(
    public reports: ReportsService,
    public user: UserDataService,
    public prop: PropagationService,
    private dialog: MatDialog,
    private http: HttpService,
    private geo: GeoService,
    private amplitude: AmplitudeService,
    public translate: TranslateService,
    private brigades: BrigadesService,
    public logging: LoggingService,
    public socketCIG: SocketService,
    public camerasService: CamerasService,
    public filterService: FilterService,
    public broadcastService: BroadcastService,
    public injector: Injector,
    private clipboard: Clipboard,
    private layer: GeoLayersService,
    ) {      
      effect(()=>{
      if(this.brigades.getDataOnBrigadeRegister()){
        this.dialog.closeAll()
        this.addAcionamento()
      }
    })}

  public acionamentoColumns = [
    'brigada',
    'acionamento',
    'combate',
    'rescaldo'
  ]

  public causas;
  public origens;
  public causasTranslated = {};
  public origensTranslated = {};

  public acionamentoLayerActive: { [key: string]: { layer: any, active: boolean } } = {};
  public damage;


  public updatedMeteoData: { [key: string]: any } = {}; 
  public lastMeteoUpdate: { [key: string]: Date } = {}; 
  private nome_local

  async translateCausas() {
    for (let causa of this.causas) {
      const traducao = `FC.${causa.id_causa_incendio}`;
      this.causasTranslated[causa.id_causa_incendio] = await firstValueFrom(this.translate.get(traducao));
    }
  }

  async translateOrigem() {
    for (let origem of this.origens) {
      const traducao = `OD.${origem.id_origem_deteccao}`;
      this.origensTranslated[origem.id_origem_deteccao] = await firstValueFrom(this.translate.get(traducao));
    }
  }
    
  ngOnInit() {
    this.causas = this.user.getDadosPantera('report_params').causas_incendio;
    this.origens = this.user.getDadosPantera('report_params').origens_deteccao;
    this.damage = this.user.getDadosPantera('report_params').danos;
    this.translateCausas();
    this.translateOrigem();  
  }

  ngOnDestroy() {
    this.removeAllAcionamentoLayers();
    this.clearPropagation();

    // Scar drawing
    this.geo.map.pm.disableDraw();
    this.geo.map.pm.disableGlobalRemovalMode();
    this.geo.map.pm.removeControls();
  }

  nextReport(){
    this.removeAllAcionamentoLayers();
    this.reports.goToReport(-1);
    this.clearPropagation();
    this.geo.openReportMarker(this.reports.selectedR.id_report);
    if(this.reports.selectedR?.propagation){
      this.prop.createPropagationFromDB();
    }
  }

  previousReport(){
    this.removeAllAcionamentoLayers();
    this.reports.goToReport(1);
    this.clearPropagation();
    this.geo.openReportMarker(this.reports.selectedR.id_report);
    if(this.reports.selectedR?.propagation){
      this.prop.createPropagationFromDB();
    }
  }

  copyReportLink(){
    const id_planta = this.reports.selectedR.id_planta
    const id_report = this.reports.selectedR.id_report;
    const accessLink = `https://pantera.umgrauemeio.com/#/map?idPlanta=${id_planta}&idReport=${id_report}`;
    this.clipboard.copy(accessLink);
    console.log('Link copied to clipboard');
  }

  unselectReport(){
    try{
      this.geo.closePopup();
    }
    catch(e){
      this.logging.logERROR(`unselectReport:closePopup ${e}`,e);
    }
    this.removeAllAcionamentoLayers();
    this.reports.unselectReport();
    this.clearPropagation();

    // Scar drawing
    this.geo.map.pm.disableDraw();
    this.geo.map.pm.disableGlobalRemovalMode();
    this.geo.map.pm.removeControls();

    // Remove per report layer groups
    this.geo.updateLayerGroup(12); // satellite detection
    this.geo.updateLayerGroup(24); // sat images
    this.geo.updateLayerGroup(25); // scar polygon
    this.geo.filterLayerGroupList();
  }

  openAddImagesDialog(){
    this.dialog.open(AddImagesDialog)
  }

  async addAcionamento(row=null){
    let brigadas = await this.http.maestroGet(`get_brigadas/${this.user.getIdPlanta()}`);
    if (!brigadas){
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translate.instant("MAP.NO_BRIGADES_REGISTERED")}`}
      });
    }

    else {
      if (this.user.getDadosPantera('pantera_features').brigades_position){
        brigadas = await this.brigades.calcBrigadesFireDistance(brigadas, this.reports.selectedR.dados_localizacao[0].lat, this.reports.selectedR.dados_localizacao[0].lon);
      };

      let dialogConfig: MatDialogConfig = {
        data: {
          row: row,
          brigadas : brigadas,
          id_report : this.reports.selectedR.id_report,
          len_acionamentos : this.reports.selectedR.acionamentos.length,
        },
        panelClass: 'dialog-responsive',
        width: '520px',
        maxWidth: '100vw',
        autoFocus: false
      }
      const dialogRef = this.dialog.open(SelectBrigadeDialog , dialogConfig);

      dialogRef.afterClosed().subscribe(async(acionamento) => {
        if (acionamento){
          for (let b of brigadas){
            if (acionamento['nome_brigada'] === b['nome_brigada']){
              acionamento['id_brigada'] = b['id_brigada']
            } 
          }
          acionamento['id_report'] = this.reports.selectedR.id_report;
          
          for (let c of ['combate', 'rescaldo']){
            if (!acionamento[c]){
              acionamento[`dt_inicio_${c}`] = '';
              acionamento[`dt_fim_${c}`] = '';
            }
          }
          
          if (row === null){
            this.reports.selectedR.acionamentos.push(acionamento);
            this.reports.selectedR.acionamentos.sort((a, b) => a.dt_acionamento.localeCompare(b.dt_acionamento));
          } else {
            let rowIndex = this.reports.selectedR.acionamentos.indexOf(row);
            this.reports.selectedR.acionamentos[rowIndex] = acionamento;
          }
          this.reports.selectedR.acionamentos = [...this.reports.selectedR.acionamentos]
  
          this.amplitude.sendEvent("Registrou Acionamento", { "ID Relatório": this.reports.selectedR.id_report, "Número Relatório": this.reports.selectedR.n_relatorio_planta, "ID Brigada": acionamento.id_brigada, "Nome Brigada": acionamento.nome_brigada })
          let response = await this.http.maestroPost('upsert_acionamento', acionamento)
          console.log('addAcionamento', response, acionamento);
          this.filterService.updateFilters()
        }
      });
    }
  }

removeAcionamento(row) {
  const dialogRef = this.dialog.open(ConfirmationDialog, {
    data: { text: this.translate.instant("MAP.DELETE_TRIGGERING") }
  });

  dialogRef.afterClosed().subscribe(async (confirmation) => {
    if (confirmation) {
      const acionamento = this.reports.selectedR.acionamentos.find(p => p === row);

      if (acionamento) {
        this.reports.selectedR.acionamentos = this.reports.selectedR.acionamentos.filter(p => p !== row);
        await this.http.maestroPost('remove_acionamento', { id_acionamento: acionamento.id_acionamento });
        this.filterService.updateFilters()
        console.log("Acionamento excluído")
      } else {
        console.error("Acionamento não encontrado");
      }
    }
  });
}

getNomesLocais(){
  return this.geo.getNomeTalhao().then(nomesTalhao => {
    this.nome_local = nomesTalhao;
  });
}

async addPerdas() {
  const idTipoDano = this.damage.tipos.map(type => type.id_tipo_dano);
  const idReport = this.reports.selectedR.id_report

  await this.getNomesLocais();
    
  const dialogRef = this.dialog.open(SelectPerdasDialog , {
    data: {
      idTipoDano: idTipoDano,
      unidades: [
        {name: this.translate.instant('MAP.HECTARES'), unit: 'ha'},
        {name: this.translate.instant('MAP.TONS'), unit: 't'},
    ],
    locais:this.nome_local
  }});

  dialogRef.afterClosed().subscribe(async(perdas) => {
    if (perdas){
      this.amplitude.sendEvent("Editou Relatório", { "ID Relatório": idReport, "Número Relatório": this.reports.selectedR.n_relatorio_planta, "Campo Editado": "Danos" })
      const dadosPerdas = { 
        id_tipo_dano: perdas.id_tipo_dano, 
        valor: perdas.valor, 
        unidade: perdas.unidade,
        nome_local: perdas.nome_local,
        id_report: idReport
      };

      let response = await this.http.maestroPost('insert_dano', dadosPerdas)
      if(response.status){
        await this.reports.updateReports();
        this.reports.selectedR = this.reports.reports_list.filter(r => r.id_report == idReport)[0]
        this.reports.setDamageLocation();
      } else{
        this.dialog.open(InfoDialog, {
          data: {text:`${this.translate.instant("MAP.ERROR")}. ${this.translate.instant("MAP.GENERIC_ERROR_MESSAGE")}`}
        });
      }
      console.log(response)
    }
  });
}

removeDamage(row) {
  const dialogRef = this.dialog.open(ConfirmationDialog, {
    data: {text: `${this.translate.instant("MAP.DELETE_DAMAGE")} (${this.translate.instant("DMG." + row.id_tipo_dano)})`}
  });

  dialogRef.afterClosed().subscribe(async (confirmation) => {
    if (confirmation){
      let perdas = this.reports.selectedR.danos.filter(p => p != row);
      this.reports.selectedR.danos = perdas

      let response = await this.http.maestroPost('delete_dano', { id_dano: row.id_dano });
      
      if(response.status){
        this.reports.setDamageLocation();
      } else{
        this.dialog.open(InfoDialog, {
          data: {text:`${this.translate.instant("MAP.ERROR")}. ${this.translate.instant("MAP.GENERIC_ERROR_MESSAGE")}`}
        });
      }
      console.log(response);
    }
  });
}

async editDamage(damage: any) {
  await this.getNomesLocais();

  const originalData = {
    id_tipo_dano: damage.id_tipo_dano,
    valor: damage.valor,
    unidade: damage.unidade,
    nome_local: damage.nome_local
  };

  const dialogRef = this.dialog.open(SelectPerdasDialog, {
    data: {
      idTipoDano: this.damage.tipos.map(type => type.id_tipo_dano),
      unidades: [
        { name: this.translate.instant('MAP.HECTARES'), unit: 'ha' },
        { name: this.translate.instant('MAP.TONS'), unit: 't' }
      ],
      locais: this.nome_local,
      id_tipo_dano: originalData.id_tipo_dano,
      valor: originalData.valor,
      unidade: originalData.unidade,
      nome_local: originalData.nome_local,
      editarDanos:true
    }
  });

  dialogRef.afterClosed().subscribe(async (dadosAtualizados) => {
    if (dadosAtualizados) {
      const hasChanges = Object.keys(originalData).some(key => originalData[key] !== dadosAtualizados[key]);

      if (hasChanges) {
        const updatedData = {id_dano: damage.id_dano,...dadosAtualizados};

        const response = await this.http.maestroPost('update_dano', updatedData);
        if (response.status) {
          Object.assign(damage, updatedData);
          this.reports.setDamageLocation();
        } else {
          this.dialog.open(InfoDialog, {
            data: { text: `${this.translate.instant("MAP.ERROR")}. ${this.translate.instant("MAP.GENERIC_ERROR_MESSAGE")}` }
          });
        }
        console.log(response)
      }
    }
  });
}

  async addComments() {
    let data = {
      text: this.translate.instant("MAP.COMMENTS"),
      comentarios: this.reports.selectedR.comentarios || ""
    }

    const dialogRef = this.dialog.open(addTextDialog , {
      data: data
    });

    dialogRef.afterClosed().subscribe(async(comments) => {
      if ((typeof(comments) == "boolean") && (comments == false)) { } // comments == false -> quando aperta em cancelar
      else if ((typeof(comments) == "object") && (comments == null)) { // comments == null -> quando confirma o texto vazio
        this.reports.selectedR.comentarios = "";
        this.amplitude.sendEvent("Editou Relatório", { "ID Relatório": this.reports.selectedR.id_report, "Número Relatório": this.reports.selectedR.n_relatorio_planta, "Campo Editado": "Comentários" })
        await this.reports.updateReportKey('comentarios')
      }
      else if (typeof(comments) == "string") {
        this.reports.selectedR.comentarios = comments;
        this.amplitude.sendEvent("Editou Relatório", { "ID Relatório": this.reports.selectedR.id_report, "Número Relatório": this.reports.selectedR.n_relatorio_planta, "Campo Editado": "Comentários" })
        await this.reports.updateReportKey('comentarios')
      }
    });
  }

  
  async toggleRisk (ob: MatSlideToggleChange) {
    this.reports.selectedR.sem_risco = ob.checked
    await this.reports.updateReportKey('sem_risco')
  }

  async toggleIsTest (ob: MatSlideToggleChange) {
    this.reports.selectedR.is_test = ob.checked
    await this.reports.updateReportKey('is_test')
  }

  async changeLocationByCoords(){
    let bounds = await this.geo.fitClientBounds()

    const dialogRef = this.dialog.open(LatLonDialog , {
      data: { bounds }
    });

    dialogRef.afterClosed().subscribe(async(latlng) => {
      if (latlng){
        this.amplitude.sendEvent("Editou Relatório", { "ID Relatório": this.reports.selectedR.id_report, "Número Relatório": this.reports.selectedR.n_relatorio_planta, "Campo Editado": "Localização" })


        let data = {
          'id_report':this.reports.selectedR.id_report,
          'id_planta': this.reports.selectedR['id_planta'],
          'simple_dados_loc': [{
            'lat': latlng.lat,
            'lon': latlng.lng
          }]
        }
        
        let response = await this.http.maestroPost('v2/update_report_loc', data)
        this.reports.selectedR.dados_localizacao[0] = response.values[0][0]
        this.reports.selectedR.dados_meteo[0] = response.values[1][0]

        await this.updateDrawReports();
        this.geo.centerOnObject({ lat: latlng.lat, lng: latlng.lng })
      }
    });
  }

  changeLocationByClick() {
    this.geo.updateWaitingClickOnMapToSetFireLocationStatus(true)
    this.geo.setUpdatingLocationMarkerOnMap(true);
    this.reports.changeCursor('crosshair');
}

  openDownloadDialog(){
    let origin = undefined;
    let cause = undefined;

    if (this.reports.selectedR.id_origem_deteccao) {
      origin = this.origens.filter(origin => {
        return origin.id_origem_deteccao == this.reports.selectedR.id_origem_deteccao
      })[0]
      if (origin) origin = origin.origem_deteccao
    }

    if (this.reports.selectedR.id_causa_incendio) {
      cause = this.causas.filter(cause => {
        return cause.id_causa_incendio == this.reports.selectedR.id_causa_incendio
      })[0]

      if (cause) cause = cause.causa_incendio
    }

    this.dialog.open(DownloadReportDialog, {
      data: {
        origin: origin,
        cause: cause
      }
    })
  }

  sendReportViaTelegram() {
    this.reports.sendReportViaTelegram()
  }

  sendReportViaWhatsapp() {
    this.reports.sendReportViaWhatsApp()
  }

  deleteReport() {
    const dialogRef = this.dialog.open(ConfirmationDialog , {
      data: {text:`${this.translate.instant("MAP.DELETE_REPORT")}`}
    });

    dialogRef.afterClosed().subscribe(async(confirmation) => {
      if (confirmation){
        try {
          let response = await this.http.maestroGet(`/delete_report_recorded/${this.reports.selectedR.id_planta}/${this.reports.selectedR.id_report}`)
          console.log("...delete_report_recorded", response)
          this.reports.reports_list = this.reports.reports_list.filter(r => r.id_report != this.reports.selectedR.id_report)
          this.reports.unselectReport()
          this.filterService.updateFilters();
          this.clearPropagation();

          try{
            this.geo.closePopup();
          }
          catch(e){
            this.logging.logERROR(`unselectReport:closePopup ${e}`,e);
          }
        }
        catch (err) {
          console.log("Erro ao deletar o relatorio ", err);
          this.logging.logERROR(`deleteReport ${err}`,err);
        }
      }
    })
  }

  async updateDrawReports(){
    await this.reports.updateReports();
    this.geo.drawFireReports(this.reports.reports_list);
  }

  async endReportChange(checked:boolean){
    this.reports.finished = checked;
    if (checked){
      this.amplitude.sendEvent("Finalizou Relatório", { "ID Relatório": this.reports.selectedR.id_report, "Número Relatório": this.reports.selectedR.n_relatorio_planta });
      this.reports.selectedR.datetime_finalizado = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en-US');
      this.clearPropagation();
    } else {
      this.amplitude.sendEvent("Desmarcou Relatório Finalizado", { "ID Relatório": this.reports.selectedR.id_report, "Número Relatório": this.reports.selectedR.n_relatorio_planta });
      this.reports.selectedR.datetime_finalizado = null;
      this.prop.createPropagationFromDB();
    }
    try {
      let response = await this.reports.updateReportKey('datetime_finalizado');
      console.log('endReportChange', this.reports.selectedR);
    }
    catch (err) {
      console.log("Erro:", err)
    }
    await this.reports.updateReports();
    this.filterService.updateFilters();

  }

  async noRiskChange(checked:boolean){
    this.reports.selectedR.sem_risco = checked;

    try {
      this.amplitude.sendEvent("Editou Relatório", { "ID Relatório": this.reports.selectedR.id_report, "Número Relatório": this.reports.selectedR.n_relatorio_planta, "Campo Editado": "Risco" })
      let response = await this.reports.updateReportKey('sem_risco');
      console.log('noRiskChange', this.reports.selectedR);
    }
    catch (err) {
      console.log("Erro:", err);
      this.logging.logERROR(`noRiskChange ${err}`,err);
    }
    await this.reports.updateReports();
    
    this.filterService.updateFilters();
    this.clearPropagation();
    if(!checked){
      this.prop.createPropagationFromDB();
    }
    
  }

  async isTestChange(checked:boolean){
    this.reports.selectedR.is_test = checked;

    try {
      this.amplitude.sendEvent("Editou Relatório", { "ID Relatório": this.reports.selectedR.id_report, "Número Relatório": this.reports.selectedR.n_relatorio_planta, "Campo Editado": "Teste" })
      let response = await this.reports.updateReportKey('is_test');
      console.log('isTestChange', this.reports.selectedR);
    }
    catch (err) {
      console.log("Erro:", err);
      this.logging.logERROR(`isTestChange ${err}`,err);
    }
    await this.reports.updateReports();
    
  }

  toggleAcionamentoLayer(idAcionamento: string){
    let acionamento = this.reports.selectedR.acionamentos.find((acionamento) => acionamento.id_acionamento === idAcionamento);
    this.acionamentoLayerActive[idAcionamento] = this.acionamentoLayerActive[idAcionamento] || { layer: undefined, active: false };
    this.acionamentoLayerActive[idAcionamento].active = !this.acionamentoLayerActive[idAcionamento].active;
    
    if (acionamento?.geojson_acionamento?.features[0]?.geometry){
      const idTipoBrigada = acionamento.id_tipo_brigada ? acionamento.id_tipo_brigada : 3; // default pickup
      if (this.acionamentoLayerActive[idAcionamento].active){
        this.acionamentoLayerActive[idAcionamento].layer = this.geo.addAcionamentoGeojsonLayer(acionamento.geojson_acionamento.features, idTipoBrigada);
      } else {
        this.geo.removeLayer(this.acionamentoLayerActive[idAcionamento].layer);
      };
    };
  }

  removeAllAcionamentoLayers(){
    if (this.reports.selectedR?.acionamentos?.length){
      for (let acionamento of this.reports.selectedR.acionamentos){
        let idAcionamento = acionamento.id_acionamento;
        if (this.acionamentoLayerActive[idAcionamento]?.active){
          this.acionamentoLayerActive[idAcionamento].active = false;
          this.geo.removeLayer(this.acionamentoLayerActive[idAcionamento].layer);
        }
      }
    }
  }


  launchPropagation(){
    this.user.currentPropagationParams.next({
      windSpeedKmh: Number((this.reports.selectedR.dados_meteo[0]['wind_speed']*MS_TO_KMH).toFixed(0)),
      windDeg: this.reports.selectedR.dados_meteo[0]['wind_deg'],
      tSimulationMin: 60,
      lat: Number(Number(this.reports.selectedR.dados_localizacao[0]['lat']).toFixed(4)),
      lon: Number(Number(this.reports.selectedR.dados_localizacao[0]['lon']).toFixed(4)),
    });
    this.prop.simulationFormSubmitted = false;
    this.user.showPropagationWidget = true;
  }

  pointCameraToReportImagePTZ() {
    let cameraId = this.reports.imgs[this.reports.selectedReportImageIndex()].img_details.id_cam
    const { pan, tilt, zoom } = this.reports.imgs[this.reports.selectedReportImageIndex()].img_details

    if (!cameraId) {
      const imagePath = this.reports.imgs[this.reports.selectedReportImageIndex()].img_path
      cameraId = this.getCameraIdFromImagePath(imagePath)
    }

    this.broadcastService.publishMessage({ event: "SelectCameraAndStopAutomaticDetection", cameraId })

    this.camerasService.absolutePTZMovement(cameraId, pan, tilt, zoom).subscribe(response => {
      console.log(response)
    })
  }

  getCameraIdFromImagePath(imagePath: string) {
    const splitted = imagePath.split('/')
    const imageName = splitted[splitted.length - 1]
    const cameraId = imageName.split('_')[0]
    return cameraId
  }

  updateDetectionOrigin() {
    this.amplitude.sendEvent("Editou Relatório", { "ID Relatório": this.reports.selectedR.id_report, "Número Relatório": this.reports.selectedR.n_relatorio_planta, "Campo Editado": "Origem" })
    this.reports.updateReportKey('id_origem_deteccao')
  }

  updateFireCause() {
    this.amplitude.sendEvent("Editou Relatório", { "ID Relatório": this.reports.selectedR.id_report, "Número Relatório": this.reports.selectedR.n_relatorio_planta, "Campo Editado": "Causa" })
    this.reports.updateReportKey('id_causa_incendio')
  }

  async deleteImageFromReport() {    
    const dialogRef = this.dialog.open(ConfirmationDialog , {
      data: { text: this.translate.instant("MAP.DELETE_IMG") }
    });

    dialogRef.afterClosed().subscribe(async(confirmation) => {
      
      if (confirmation){
        let data = {
          "img_uuid": this.reports.imgs[this.reports.selectedReportImageIndex()].img_uuid,
          "img_path": this.reports.imgs[this.reports.selectedReportImageIndex()].img_path,
          "img_index": this.reports.selectedReportImageIndex(),
        }
        let maestroResponse = await this.reports.deleteImage(data)
        maestroResponse = JSON.parse(maestroResponse)
        if (maestroResponse.reponse_s3 == 204) { // so exclui do front se tiver sucesso no backend
          let deleting_index = this.reports.selectedReportImageIndex()
          let final_index = 0
          try {
              if (deleting_index == 0) {
                final_index = 0;
              }
              else if (deleting_index == this.reports.imgs.length - 1) {
                final_index = 0;
              }
              else {
                final_index = deleting_index;
              }
              this.reports.imgs.splice(deleting_index, 1)
              this.reports.setSelectedReportImageIndex(final_index)
          }
          catch (err) {
            console.log(err);
            this.logging.logERROR(err,err);
          }
        }
        else {
          console.log("Falha ao excluir a imagem no DB", maestroResponse)
        }
      }
    })
  }

  async updateMeteoData(reportId: string) {
    const lat = this.reports.selectedR.dados_localizacao[0].lat;
    const lon = this.reports.selectedR.dados_localizacao[0].lon;
    try {
      const updatedData = await this.getMeteoData(lat, lon);
      this.updatedMeteoData[reportId] = updatedData;
      this.lastMeteoUpdate[reportId] = new Date();
      this.amplitude.sendEvent("Atualizou Dados Meteorológicos", { "ID Relatório": this.reports.selectedR.id_report, "Número Relatório": this.reports.selectedR.n_relatorio_planta})
    } catch (error) {
      console.error("Erro ao atualizar dados meteorológicos:", error);
    }
  }
  
  async getMeteoData(lat: number, lon: number): Promise<any> {
    console.log('lat', lat)
    console.log('lat', lon)
    try {
      const response = await this.http.maestroGet(`get_met/reduced_one_call/${lat}/${lon}/`);
      console.log(response)
      return response;
    } catch (error) {
      console.error("Erro ao obter dados meteorológicos:", error);
      return null;
    }
  }

  clearPropagation(){
    this.geo.clearFirefronts();
    clearTimeout(this.prop.drawPropTimeoutId);
  }

  drawScarPolygon(){
    this.geo.activateLayer(25, 'REP.REPORT_SCAR_LAYER');

    this.geo.map.pm.addControls({
      position: 'bottomleft',
      drawMarker: false,
      drawCircle: false,
      drawCircleMarker: false,
      drawPolyline: false,
      drawRectangle: false,
      drawPolygon: true,
      drawText: false,
      editMode: false,
      cutPolygon: false,
      dragMode: false,
      rotateMode: false,
      removalMode: true,
    });

    this.geo.map.pm.Toolbar.changeControlOrder([
      "drawPolygon: true,",
      "removalMode"
    ]);

  }

  async reloadReport() {
    const idReport = this.reports.selectedR.id_report;

    await this.reports.getSingleReport(idReport);
    this.geo.drawFireReports(this.reports.reports_list);
    console.log("Atualizou relatório", this.reports.selectedR);
  }

}